import { HeadingXxl, HeadingLg } from '/features/buildingBlocks/Heading'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useTrail, useSpring, animated } from '@react-spring/web'
import { ContainerLg } from '/features/buildingBlocks/Container'

import styles from './FactsAndFigures.css'

export function FactsAndFigures({ items, title }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({
    threshold: [1],
  })
  const springConfig = {
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(100%)',
    },
    config: { tension: 150, friction: 35 },
  }
  const headingSpring = useSpring({ ...springConfig })
  const trail = useTrail(items?.length ?? 0, {
    delay: 200,
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(50%)',
    },
    config: { tension: 150, friction: 35 },
  })

  return items && (
    <div ref={elementRef} className={styles.component}>
      <div className={styles.content}>
        {title && (
          <animated.div style={headingSpring} className={styles.heading}>
            <HeadingLg h='2'>{title}</HeadingLg>
          </animated.div>
        )}
        <ul className={styles.items}>
          {items.map((item, i) => (
            <animated.li key={i} className={styles.item} style={trail[i]}>
              <div className={styles.itemHeading}>
                <HeadingXxl h='3'>{item.title}</HeadingXxl>
              </div>
              <p className={styles.text}>{item.text}</p>
            </animated.li>
          ))}
        </ul>
      </div>
      <div role='presentation' className={styles.background} />
    </div>
  )
}
